import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import "./App.css";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import {
  CssBaseline,
  ThemeProvider,
  createTheme,
  StyledEngineProvider,
} from "@mui/material";
import { Helmet, HelmetProvider } from "react-helmet-async";
//init langauge
import "./i18n/config";

const theme = createTheme();

ReactDOM.hydrate(
  <HelmetProvider>
    <Helmet prioritizeSeoTags>
      <title>C•Collectibles</title>
      <meta name="description" content="[I/PROMPTING] INCLUSIVITY FOR ALL WOMEN ให้ผู้หญิง[/พร้อมเป็นผู้ขับเคลื่อนอย่างแท้จริง" />
      <meta name="keywords" content="nft, womenday central, c-collectible" />
      <meta name="author" content="Central Retail" />
      <meta property="og:title" content="C•Collectibles" />
      <meta property="og:description" content="[I/PROMPTING] INCLUSIVITY FOR ALL WOMEN ให้ผู้หญิง[/พร้อมเป็นผู้ขับเคลื่อนอย่างแท้จริง" />
      <meta
        property="og:image"
        content="https://wallet.central.tech/favicon/android-chrome-192x192.png"
      />
      <meta property="og:url" content={window.location.origin} />
      <meta name="twitter:title" content="C•Collectibles" />
      <meta name="twitter:description" content="[I/PROMPTING] INCLUSIVITY FOR ALL WOMEN ให้ผู้หญิง[/พร้อมเป็นผู้ขับเคลื่อนอย่างแท้จริง" />
      <meta
        name="twitter:image"
        content="https://wallet.central.tech/favicon/android-chrome-192x192.png"
      />
      <meta name="twitter:card" content="summary_large_image" />
    </Helmet>
    <BrowserRouter>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <App />
        </ThemeProvider>
      </StyledEngineProvider>
    </BrowserRouter>
  </HelmetProvider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
