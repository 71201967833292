import { FunctionComponent, useEffect, useState } from "react";
import styles from "../LandingPage.module.css";
import { useNavigate, useLocation } from "react-router-dom";
import Image from "@components/Image";

interface CardProps {
  type?: any;
}

export const Creator: FunctionComponent<CardProps> = ({ type }) => {

    
  if (type === "CDS") {
    return (
      <div className={styles.frameDiv33}>
        <img className={styles.frameIcon} alt="" src="../logo-central.svg" />
        <div className={styles.frameDiv4}>
          <b className={styles.myCollectionsC}>Central Department Store</b>
        </div>
      </div>
    );
  } else if (type === "B2S") {
    return (
      <div className={styles.frameDiv33}>
        <img className={styles.frameIcon} alt="" src="../Logo-b2s.svg" />
        <div className={styles.frameDiv4}>
          <b className={styles.myCollectionsC}>B2S</b>
        </div>
      </div>
    );
  } else if (type === "TOPS") {
    return (
      <div className={styles.frameDiv33}>
        <img className={styles.frameIcon} alt="" src="../Logo-TOP.svg" />
        <div className={styles.frameDiv4}>
          <b className={styles.myCollectionsC}>Tops</b>
        </div>
      </div>
    );
  } else if (type === "CVERSE") {
    return (
      <div className={styles.frameDiv33}>
        <img className={styles.frameIcon} alt="" src="../Logo-CVERSE.svg" />
        <div className={styles.frameDiv4}>
          <b className={styles.myCollectionsC}>C-Verse</b>
        </div>
      </div>
    );
  } else if (type === "METAWARDEN") {
    return (
      <div className={styles.frameDiv33}>
        <img className={styles.frameIcon} alt="" src="../Logo-META.svg" />
        <div className={styles.frameDiv4}>
          <b className={styles.myCollectionsC}>Metawarden</b>
        </div>
      </div>
    );
  }
  return <div />;
};
