import { FunctionComponent, useEffect, useState } from "react";
import styles from "../LandingPage.module.css";
import { useNavigate, useLocation } from "react-router-dom";
import Image from "@components/Image";
import Loader from "@components/Loader";
import { Creator } from "./Creator";
import { Box, CircularProgress } from "@mui/material";
import { ALLOW_ADDRESS } from "@contracts/HoldNFT";

interface CardProps {
  image?: any;
}

function useWindowSize() {
  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const [windowSize, setWindowSize]: any = useState({
    width: undefined,
    height: undefined,
  });

  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }
    // Add event listener
    window.addEventListener("resize", handleResize);
    // Call handler right away so state gets updated with initial window size
    handleResize();
    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty array ensures that effect is only run on mount
  return windowSize;
}

export const Card: FunctionComponent<CardProps> = ({ image }) => {
  const size = useWindowSize();
  const navigate = useNavigate();

  if (image.length === 0)
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="50%"
        sx={{
          backgroundColor: "#0a0a0a",
        }}
      >
        {/* <CircularProgress color={"error"} /> */}
      </Box>
    );
  let ImageFilter = [];

  const goToDetails = (item: any) => {
    navigate({
        pathname: '/collections-detail',
        search: `?address=${item.contractAddress}&tokenID=${item.tokenID}`,
      });
  };

  //check resize card
  if (size.width < 768) {
    ImageFilter = image.reduce(function (r: any[], v: any, i: number) {
      if (i % 2 === 0) r.push(image.slice(i, i + 2));
      return r;
    }, []);
  } else if (size.width <= 1200) {
    ImageFilter = image.reduce(function (r: any[], v: any, i: number) {
      if (i % 4 === 0) r.push(image.slice(i, i + 4));
      return r;
    }, []);
  } else {
    ImageFilter = image.reduce(function (r: any[], v: any, i: number) {
      if (i % 6 === 0) r.push(image.slice(i, i + 6));
      return r;
    }, []);
  }

  return (
    <div>
      {ImageFilter.map((item: any, index: any) => (
        <div className={styles.myCollectionRow01Div} key={index}>
          <div className={styles.colum12Div}>
            {item.map((item: any, index: any) => (
              <div
                className={styles.colum2Div}
                key={` ${item.name} #${item.tokenID}`}
                onClick={() => goToDetails(item)}
              >
                <Image
                  className={styles.rectangleIcon}
                  src={item.image}
                  alt={item.image}
                />
                <div className={styles.frameDiv3}>
                  <div className={styles.frameDiv4}>
                    <b className={styles.myCollectionsB}>
                      {item.name} {item.contractAddress !== ALLOW_ADDRESS ? `#${item.tokenID}`:null}
                    </b>
                  </div>
                </div>
                <Creator type={item.creator} />
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};
