import { FunctionComponent, useRef, useState, useEffect } from "react";
import { Button } from "@mui/material";
import styles from "./css/MyCollectionsDetails.module.css";
import { slicedAddress } from "@utils/convert";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { useLocation, useNavigate } from "react-router-dom";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import { makeStyles } from "@mui/styles";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { authAtom, usersAtom } from "../../../src/state";
import Image from "@components/Image";
import queryString from "query-string";
import Web3 from "web3";
import { ABI, ADDRESS } from "@contracts/NFT";
import {
  FacebookShareButton,
  FacebookIcon,
  LineIcon,
  LinkedinIcon,
  LineShareButton,
  LinkedinShareButton,
  TwitterIcon,
  TwitterShareButton,
  WorkplaceShareButton,
  TelegramShareButton,
  XIcon,
  WorkplaceIcon,
  TelegramIcon,
} from "react-share";
// import Countdown from 'react-countdown';
import Loader from "@components/Loader";

import { CountdownTimer } from "@components/CountDown";

import axios from "axios";
import { useRecoilValue } from "recoil";
import { useOAuth } from "@services/Oauth";

import { Creator } from "./elements/Creator";
import { ALLOW_ADDRESS } from "@contracts/HoldNFT";

const useStyles = makeStyles({
  breadcrumbs: {
    "& .MuiBreadcrumbs-separator": {
      color: "#fff",
      fontSize: "10px",
    },
  },
  disabledButton: {
    "&:disabled": {
      backgroundColor: "#6f6a6a",
    },
  },
  badgeNormal: {
    fontSize: 12,
    padding: 10,
    textTransform: "uppercase",
    backgroundColor: "#C3CFE2",
    color: "#000",
    height: 35,
    width: 100,
    marginTop: 18,
  },
  badgeRare: {
    fontSize: 12,
    padding: 10,
    textTransform: "uppercase",
    backgroundColor: "#3498DB",
    color: "#000",
    height: 35,
    width: 100,
    marginTop: 18,
  },
  badgeSpeaical: {
    fontSize: 12,
    padding: 10,
    textTransform: "uppercase",
    backgroundColor: "#D1913C",
    color: "#000",
    height: 35,
    width: 100,
    marginTop: 18,
  },
});

const womens = [
  "0xD10B8cB5312B8Ff3E46964a1BCcFb8358F4d55d5",
  "0x46583209C6baf35A0E5a82A46Ba5f51Fcf8C8c8A",
  "0x384589D4DAE51CC8f64fBFF3F1271e5402d107df",
  "0x416e4Ac0e1E1892Bf398390553eE104AfC4E3a2D",
];

export const MyCollectionsDetails: FunctionComponent = () => {
  const web3: any = new Web3("https://rpc.omplatform.com/");
  const { logout, pushEvent, getBalance, refreshToken } = useOAuth();
  const auth: any = useRecoilValue(authAtom);
  const user: any = useRecoilValue(usersAtom);

  // const location = useLocation();
  const { address, tokenID, tick_event } = queryString.parse(
    window.location.search
  );
  // const detail:any = location.state;
  const navigate = useNavigate();
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [opening, setOpening]: any = useState(true);
  const [timeing, setTimeing]: any = useState(null);
  // const [att, setAtt]: any = useState(null);
  const [detail, setDetail]: any = useState(null);

  const [item_type, setItemType]: any = useState(null);
  const [item_level, setItemLevel]: any = useState(null);
  const [item_benefit, setItemBenefit]: any = useState(null);

  // const [audio,setAudio] = useState<any>(null);
  const [audio, setAudio] = useState(new Audio(""));
  const [playing, setPlaying] = useState(true);

  useEffect(() => {
    if (detail && detail.animation_url) {
      playing ? audio.play() : audio.pause();
    }
  }, [detail, playing]);

  useEffect(() => {
    audio.addEventListener("ended", () => setPlaying(false));
    return () => {
      audio.removeEventListener("ended", () => setPlaying(false));
    };
  }, []);

  useEffect(() => {
    const initNFT = async () => {
      await refreshToken();

      setLoading(true);
      const token_id: any = tokenID;
      const contract = await new web3.eth.Contract(ABI, address);

      const addr = await contract.methods.ownerOf(token_id).call();
      if (user.wallet.address !== addr.toLowerCase()) return navigate("/");

      getNFTMetadata(contract, token_id);
    };

    if (address && user) initNFT();

    return () => {};
  }, [address, user]);

  //check balance
  useEffect(() => {
    const fetchBalance = async () => {
      const balance = await getBalance(user.wallet.address);
      const total: any = parseFloat(balance[0].balance).toFixed(2);
      if (total < 0.5) {
        await pushEvent();
      }
    };

    if (user) fetchBalance();
    return () => {};
  }, [user]);

  //events ccc
  useEffect(() => {
    const init = async () => {
      setLoading(true);

      localStorage.setItem(
        "current",
        JSON.stringify({ url: window.location.href })
      );

      await gotoOpening(detail);
    };

    if (detail && tick_event === "ccc") init();
    return () => {};
  }, [detail]);

  async function getNFTMetadata(contract: any, tokenID: string) {
    const result = await contract.methods.tokenURI(tokenID).call();

    const ipfsURL = addIPFSProxy(result);
    if (ipfsURL) {
      try {
        const request: any = new Request(ipfsURL);
        const response = await fetch(request);
        const metadata = await response.json();
        const image: string = addIPFSProxy(metadata.image);
        const data: any = {
          ...metadata,
          image,
          tokenID,
          contractAddress: contract._address,
        };

        setDetail(data);

        //set attr
        const att = data.attributes;
       
        var item_type = att.filter(function (item: any) {
          return item.trait_type === "Type";
        });

        setItemType(item_type);

        const item_level = att.filter(function (item: any) {
          return item.trait_type === "Level";
        });
        setItemLevel(item_level);
        const item_benefit = att.filter(function (item: any) {
          return item.trait_type === "Benefit";
        });
        setItemBenefit(item_benefit);

        if (item_type.length > 0 && item_type[0].value === "Box") {
          const items = {
            address: data.contractAddress,
            id: data.tokenID,
          };
          await axios
            .post("/webhook/active-date", items, {
              headers: {
                "Content-Type": "application/json",
                Authorization: `${auth.token_type} ${auth.access_token}`,
              },
            })
            .then(function (response) {
              const data = response.data;
              const NOW_IN_MS = new Date(
                parseInt(data.data.active_date)
              ).getTime();
              setTimeing(NOW_IN_MS);
            })
            .catch(function (error) {});
        }

        //set audio
        const audio_url: any = new Audio(data.animation_url || "");
        setAudio(audio_url);

        // setDetail(old => [...old, data])
        setLoading(false);
      } catch (error) {
        console.log(error);
      }
    }
  }
  function addIPFSProxy(ipfsHash: string) {
    // if (!ipfsHash.startsWith('https://'))  return ipfsHash.replace('https://c-collectible-static.s3.ap-southeast-1.amazonaws.com', '')
    if (!ipfsHash.startsWith("ipfs://")) {
      const hash = ipfsHash.replace(
        "https://c-collectible-static.s3.ap-southeast-1.amazonaws.com",
        ""
      );
      return hash;
    }
    // const URL = "https://cloudflare-ipfs.com/ipfs/"

    // const ipfsURL = URL + hash
    // return ipfsHash
    return "";
  }

  const gotoTransfer = (item: any) => {
    navigate("/collections-transfer", {
      state: {
        item,
      },
      replace: false,
    });
  };

  const gotoOpening = async (item: any) => {
    if (!tick_event) {
      if (opening.hours || opening.minutes) {
        return false;
      }
    }

    setLoading(true);
    const data = {
      contract_address: item.contractAddress,
      token_id: item.tokenID,
    };
    localStorage.removeItem("current");
    const result = await axios
      .post("/webhook/unbox", data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `${auth.token_type} ${auth.access_token}`,
        },
      })
      .then(function (response) {
        const data = response.data;
        setTimeout(function () {
          setLoading(false);
          return navigate("/open-status", {
            state: {
              data,
            },
            replace: true,
          });
        }, 5000);
      })
      .catch(function (error) {
        const data = error.response.data;
        setTimeout(function () {
          setLoading(false);
          return navigate("/open-status-fail", {
            state: {
              data,
            },
            replace: true,
          });
        }, 5000);
      });
  };

  const goToHome = () => {
    audio.pause();
    navigate("/");
  };

  if (!detail || loading || tick_event) return <Loader />;

  function renderImage() {
    if (item_level.length > 0) {
      if (item_level[0].value === "Normal") {
        return (
          <Image
            className={`${styles.itemImageDiv} ${styles.ImageNormal}`}
            src={detail.image}
            alt={detail.image}
          />
        );
      } else if (
        item_level[0].value === "rare" ||
        item_level[0].value === "Rare" ||
        item_level[0].value === "Master"
      ) {
        return (
          <Image
            className={`${styles.itemImageDiv} ${styles.ImageRare}`}
            src={detail.image}
            alt={detail.image}
          />
        );
      } else {
        return (
          <Image
            className={`${styles.itemImageDiv} ${styles.ImageSpeacial}`}
            src={detail.image}
            alt={detail.image}
          />
        );
      }
    } else {
      return (
        <Image
          className={`${styles.itemImageDiv}`}
          src={detail.image}
          alt={detail.image}
        />
      );
    }
  }

  const activeShare = womens.includes(detail.contractAddress) ? true : false;

  const shareLink =
    window.location.origin +
    "/preview/" +
    detail.contractAddress +
    "/" +
    detail.tokenID;
  return (
    <div className={styles.myCollectionsDetailsDiv}>
      <div className={styles.pAGETITLEDiv}>
        <div className={styles.pAGETITLEDiv1}>
          <span className={styles.BackHistory} onClick={() => goToHome()}>
            <ChevronLeftIcon />
            <b className={styles.transferItemB}>
              {detail.name}{" "}
              {detail.contractAddress !== ALLOW_ADDRESS
                ? `#${detail.tokenID}`
                : null}
            </b>
          </span>
        </div>
        <div role="presentation">
          <Breadcrumbs
            aria-label="breadcrumb"
            className={classes.breadcrumbs}
            separator={<KeyboardArrowRightIcon fontSize={"small"} />}
          >
            <Link
              underline="hover"
              color="white"
              onClick={() => goToHome()}
              fontSize={12}
            >
              Home
            </Link>
            <Typography color="white" fontSize={12}>
              {detail.name}{" "}
              {detail.contractAddress !== ALLOW_ADDRESS
                ? `#${detail.tokenID}`
                : null}
            </Typography>
          </Breadcrumbs>
        </div>
      </div>

      <div className={styles.frameDiv3}>
        <div className={styles.pAGECONTENTDiv}>
          <div className={styles.colDiv}>
            {renderImage()}
            {/* <button onClick={toggle}>{playing ? "Pause" : "Play"}</button> */}
          </div>

          <div className={styles.colDiv1}>
            <Creator
              type={detail.creator}
              level={item_level}
              benefit={item_benefit}
            />
            {activeShare ? (
              <div className={styles.divShare}>
                <FacebookShareButton url={shareLink}>
                  <FacebookIcon size={32} round />
                </FacebookShareButton>
                <LineShareButton url={shareLink}>
                  <LineIcon size={32} round />
                </LineShareButton>
                <LinkedinShareButton url={shareLink}>
                  <LinkedinIcon size={32} round />
                </LinkedinShareButton>
                <TwitterShareButton url={shareLink}>
                  <XIcon size={32} round />
                </TwitterShareButton>
                <WorkplaceShareButton url={shareLink}>
                  <WorkplaceIcon size={32} round />
                </WorkplaceShareButton>
              </div>
            ) : null}

            {detail.description !== "" ? (
              <div>
                <b className={styles.victoriaOilpaint07}>Details:</b>
                <div className={styles.notTheSharpestToolInTheS}>
                  {detail.description}
                </div>
              </div>
            ) : (
              <div> &nbsp;</div>
            )}
            <div className={styles.frameDiv5}>
              {item_type.length > 0 &&
              item_type[0].value === "Box" &&
              timeing ? (
                <Button
                  disabled={opening}
                  className={styles.btnTransfer}
                  classes={{ disabled: classes.disabledButton }}
                  sx={{
                    width: 300,
                    textTransform: "unset",
                  }}
                  variant="contained"
                  color="error"
                  onClick={() => gotoOpening(detail)}
                >
                  {timeing ? (
                    <CountdownTimer
                      targetDate={timeing}
                      setOpening={setOpening}
                    />
                  ) : (
                    "..."
                  )}
                </Button>
              ) : (
                <div />
              )}

              {address !== "0xBf842f81e3939F7E6f0eC0dA51CbeA289e5153b2" ? (
                <Button
                  className={styles.btnTransfer}
                  sx={{
                    width: 300,
                    textTransform: "unset",
                  }}
                  variant="contained"
                  color="error"
                  onClick={() => gotoTransfer(detail)}
                >
                  TRANSFER
                </Button>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
