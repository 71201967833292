/* eslint-disable max-len */
import React, { Fragment, Suspense, useEffect, useState } from "react";
import {
  BrowserRouter,
  Routes,
  Route,
  useLocation,
  useNavigate,
  Outlet,
  Navigate,
} from "react-router-dom";
import { useOAuth } from "@services/Oauth";
import Loader from "@components/Loader";
import queryString from "query-string";

//screen
import Layout from "@components/Layout/Layout";
import Home from "@pages/Home/Home";
import Login from "@pages/Login";

import { MyCollectionstransfer } from "@pages/MyCollection/MyCollectionstransfer";
import { MyCollectionsDetails } from "@pages/MyCollection/MyCollectionsDetails";
import { MyCollectionsItemDetails } from "@pages/MyCollection/MyCollectionsItemDetails";
import { MyCollectionstransferFail } from "@pages/MyCollection/MyCollectionstransferFail";
import { MyCollectionsLists } from "@pages/MyCollection/MyCollectionsLists";

import { Profile } from "@pages/profiles/Profile";
import { Preview } from "@pages/Preview/Preview";

import { ProfileLogOut } from "./ProfileLogOut";

import { RewardsHistoryDetail } from "./Rewards/RewardsHistoryDetail";
import { RewardsHistory } from "./Rewards/RewardsHistory";
import { RewardsStatus } from "./Rewards/RewardsStatus";
import { RewardsStatusFail } from "./Rewards/RewardsStatusFail";

import { Rewards } from "./Rewards/Rewards";

import { Policy } from "./Policy/Policy";
import Callback from "./Login/callback";
import { Gift } from "./Gift/Gift";
import { GiftStatus } from "./Gift/RewardsStatus";
import { GiftStatusFail } from "./Gift/RewardsStatusFail";
//state
import { useRecoilValue } from "recoil";
import { authAtom } from "../state";
import axios from "axios";

const ProtectedRoute = ({ auth, redirectPath = "/landing", children }: any) => {
  if (!auth) {
 
    if (window.location.pathname !== "/") {
      localStorage.setItem(
        "current",
        JSON.stringify({ url: window.location.href })
      );
    }    
    return <Navigate to={redirectPath} replace />;
  }

  return <Layout>{children ? children : <Outlet />}</Layout>;
};

function RouterAuth() {
  return (
    <Layout>
      <Routes>
        <Route path="/" element={<ProfileLogOut />} />
      </Routes>
    </Layout>
  );
}

const Index = () => {
  const { isLoading, getUserInfo } = useOAuth();

  const auth = useRecoilValue(authAtom);
  // const { redirectTo } = queryString.parse(location.search);
  const { state, code, redirectTo, addr } = queryString.parse(
    window.location.search
  );

  useEffect(() => {
    if (addr) {
      localStorage.setItem("contactAddress", addr as string);
    }

    if (auth) {
      getUserInfo(auth);
    }

    return () => {};
  }, [auth]);

  if (redirectTo === "logout") return <RouterAuth />;

  if (isLoading) return <Loader />;

  return (
    // <Layout>
    <Suspense fallback={<Loader />}>
      <Routes>
        <Route path="landing" element={<Login code={code} />} />
        <Route path="callback" element={<Callback code={code} />} />
        <Route element={<ProtectedRoute auth={auth} />}>
          <Route path="/" element={<Home />} />
          <Route path="/profile" element={<Profile />} />

          <Route path="/collection" element={<MyCollectionsLists />} />

          <Route
            path="/collections-detail"
            element={<MyCollectionsDetails />}
          />
          <Route
            path="/collections-transfer"
            element={<MyCollectionsItemDetails />}
          />
          <Route
            path="/collection-status"
            element={<MyCollectionstransfer />}
          />
          <Route
            path="/collection-status-fail"
            element={<MyCollectionstransferFail />}
          />

          <Route path="/open-status" element={<MyCollectionstransfer />} />
          <Route
            path="/open-status-fail"
            element={<MyCollectionstransferFail />}
          />

          <Route path="/logout" element={<ProfileLogOut />} />

          {/* <Route path="/profile" element={<ProfileNew />} /> */}
          {/* <Route path="/news" element={<LastestNews />} />
              <Route path="/news-details" element={<NewDetails />} />
              
            
              <Route path="/recently" element={<Recently />} />
              <Route path="/recently-detal" element={<RecentlyDetail />} /> */}

          <Route path="/redeem-detail" element={<RewardsHistoryDetail />} />
          <Route path="/redeem-history" element={<RewardsHistory />} />
          <Route path="/redeem-status" element={<RewardsStatus />} />
          <Route path="/redeem-status-fail" element={<RewardsStatusFail />} />
          <Route path="/redeem" element={<Rewards />} />

          <Route path="/policy" element={<Policy />} />

          <Route path="/gift/:addr" element={<Gift />} />
          <Route path="/gift-status" element={<GiftStatus />} />
          <Route path="/gift-status-fail" element={<GiftStatusFail />} />
        </Route>

        <Route
          path="/preview/:addr/:id"
          element={
            <Layout>
              <Preview />
            </Layout>
          }
        />
        <Route path="*" element={<p>There's nothing here: 404!</p>} />
      </Routes>
    </Suspense>
    // </Layout>
  );

  // if (auth){
  //   return <Router />
  // }else{
  //   return <InitRouter code={code} />
  // }
};

export default Index;
