import React, { FunctionComponent, useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router";
import Web3 from "web3";
import { ABI, ADDRESS } from "@contracts/NFT";
import axios from "axios";
import Image from "@components/Image";
import styles from "./css/MyCollectionsDetails.module.css";
import Loader from "@components/Loader";
import { Helmet } from "react-helmet-async";
import { Button } from "@mui/material";
import { useOAuth } from "@services/Oauth";
import { useRecoilValue } from "recoil";
import { authAtom } from "../../../src/state";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";

import {GiftAll} from "./GiftAll";

export const Gift: FunctionComponent = () => {
  const web3: any = new Web3("https://rpc.omplatform.com/");
  const [loading, setLoading] = useState(false);
  const [detail, setDetail]: any = useState(null);
  const [item_type, setItemType]: any = useState(null);
  const [item_level, setItemLevel]: any = useState(null);
  const [item_benefit, setItemBenefit]: any = useState(null);

  
  const auth:any = useRecoilValue(authAtom);
  const navigate = useNavigate();
  const { logout } = useOAuth();
  const { addr } = useParams();

  useEffect(() => {
    const initNFT = async () => {
      setLoading(true);
      const token_id: any = 1;
      const contract = await new web3.eth.Contract(ABI, addr);
      getNFTMetadata(contract, token_id);
    };

    if (addr !== 'shepossible') initNFT();

    return () => {};
  }, [addr]);

  async function getNFTMetadata(contract: any, tokenID: string) {
    const result = await contract.methods.tokenURI(tokenID).call();

    const ipfsURL = addIPFSProxy(result);
    if (ipfsURL) {
      try {
        const request: any = new Request(ipfsURL);
        const response = await fetch(request);
        const metadata = await response.json();

        const images: string = addIPFSProxy(metadata.image);
        const data: any = {
          ...metadata,
          images,
          tokenID,
          contractAddress: contract._address,
        };

        setDetail(data);

        // //set attr
        const att = data.attributes;
        var item_type = att.filter(function (item: any) {
          return item.trait_type === "Type";
        });

        setItemType(item_type);

        const item_level = att.filter(function (item: any) {
          return item.trait_type === "Level";
        });
        setItemLevel(item_level);
        const item_benefit = att.filter(function (item: any) {
          return item.trait_type === "Benefit";
        });
        setItemBenefit(item_benefit);

        // setDetail(old => [...old, data])
        setLoading(false);
      } catch (error) {
        console.log(error);
      }
    }
  }
  function addIPFSProxy(ipfsHash: string) {
    // if (!ipfsHash.startsWith('https://'))  return ipfsHash.replace('https://c-collectible-static.s3.ap-southeast-1.amazonaws.com', '')
    if (!ipfsHash.startsWith("ipfs://")) {
      const hash = ipfsHash.replace(
        "https://c-collectible-static.s3.ap-southeast-1.amazonaws.com",
        ""
      );
      return hash;
    }
    // const URL = "https://cloudflare-ipfs.com/ipfs/"

    // const ipfsURL = URL + hash
    // return ipfsHash
    return "";
  }

  function renderImage() {
    return (
      <Image
        className={`${styles.itemImageDiv}`}
        src={detail.images}
        alt={detail.name}
      />
    );
  }

 

  const goToHome = () => {
    navigate(-1)
  };

  const recieveNFT = async () => {

    const data = {
      address:addr,
      // name:detail.name,
      // img : detail.images,
    }
    setLoading(true);

    const result = await axios.post('/webhook/ccw-qr-code',data, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `${auth.token_type} ${auth.access_token}`
      }
    }).then(function (response) {
     
      const data = response.data
      setTimeout(function() {
        setLoading(false);
        return navigate('/gift-status', {
          state: {
            data,
          },
          replace: true 
        });
      }, 3000);
    })
    .catch(function (error) {

      if (error.response) {
        if (error.response.status === 403){
          logout()
        }
      }

      const data = error.response.data
      setTimeout(function() {
        setLoading(false);
        return navigate('/gift-status-fail', {
          state: {
            data,
          },
          replace: true 
        })
      }, 3000);
    });

  };

  if (addr === 'shepossible') return <GiftAll />

  if (!detail || loading) return <Loader />;

  const shareLink =
    window.location.origin +
    "/preview/" +
    detail.contractAddress +
    "/" +
    detail.tokenID;

  return (
    <>
      {/* <Helmet prioritizeSeoTags>
        <title>Gift{detail.name}</title>
        <meta name="description" content={detail.description} />
        <meta name="keywords" content="nft, womenday central, c-collectible" />
        <meta name="author" content="Central Retail" />
        <meta property="og:title" content={detail.name} />
        <meta property="og:description" content={detail.description} />
        <meta property="og:image" content={detail.image} />
        <meta property="og:url" content={shareLink} />
        <meta name="twitter:title" content={detail.name} />
        <meta name="twitter:description" content={detail.description} />
        <meta name="twitter:image" content={detail.image} />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet> */}
      <div className={styles.myCollectionsDetailsDiv}>
      <div className={styles.pAGETITLEDiv}>
        <div className={styles.pAGETITLEDiv1}>
          <span className={styles.BackHistory} onClick={() => goToHome()}>
            <ChevronLeftIcon />
            
            <b className={styles.transferItemB}>
            Back
            </b>
          </span>
        </div>
       
      </div>
        <div className={styles.frameDiv3}>
          <div className={styles.pAGECONTENTDiv}>
            <div className={styles.colDiv}>
              {renderImage()}
              {/* <button onClick={toggle}>{playing ? "Pause" : "Play"}</button> */}
            </div>

            <div className={styles.colDiv1}>
              <div className={styles.cardSeller01Div}>
                {detail.name} # {detail.tokenID}
              </div>
              {detail.description !== "" ? (
                <div>
                  <div className={styles.notTheSharpestToolInTheS}>
                    {detail.description}
                  </div>
                </div>
              ) : (
                <div> &nbsp;</div>
              )}

              <div className={styles.frameDiv5}>
                <Button
                  className={styles.btnTransfer}
                  sx={{
                    width: 300,
                    textTransform: "unset",
                  }}
                  variant="contained"
                  color="error"
                  onClick={() => recieveNFT()}
                >
                  RECEIVE
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};



