
export const slicedAddress = (account: string | any[]):string  => {
  return `${account.slice(0, 6)}...${account.slice(-4)}`;
};


export const addIPFSProxy = (ipfsHash: string | any):string  => {
  // if (!ipfsHash.startsWith('https://'))  return ipfsHash.replace('https://c-collectible-static.s3.ap-southeast-1.amazonaws.com', '')
  if (!ipfsHash.startsWith("ipfs://")) {
    const hash = ipfsHash.replace(
      "https://c-collectible-static.s3.ap-southeast-1.amazonaws.com",
      ""
    );
    return hash;
  }
  // const URL = "https://cloudflare-ipfs.com/ipfs/"

  // const ipfsURL = URL + hash
  // return ipfsHash
  return "";
};

export const mergeDedupe = (arr : any) =>{
  return [...new Set([].concat(...arr))];
}