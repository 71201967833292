/* eslint-disable max-len */

import {
  createContext,
  FunctionComponent,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import axios from "axios";
import { useSetRecoilState } from "recoil";
import moment from "moment";
import { authAtom, usersAtom } from "../state";

export interface IWeb3AuthContext {
  isLoading: boolean;
  user: unknown;
  login: () => Promise<any>;
  logout: () => Promise<void>;
  getUserInfo: (data: any) => Promise<any>;
  sendTransaction: (data: any) => Promise<any>;
  getBalance: (data: string) => Promise<any>;
  verifyAuthCode: (code: any) => Promise<any>;
  pushEvent: () => Promise<any>;
  callGiftNft: (code: any) => Promise<any>;
  refreshToken: () => Promise<any>;
}

export const OAuthContext = createContext<IWeb3AuthContext>({
  isLoading: false,
  user: null,
  login: async () => {},
  logout: async () => {},
  getUserInfo: async (data) => {},
  sendTransaction: async (data) => {},
  getBalance: async (data) => {},
  verifyAuthCode: async (code: any) => {},
  pushEvent: async () => {},
  callGiftNft: async (addr: any) => {},
  refreshToken: async () => {},
});

export function useOAuth() {
  return useContext(OAuthContext);
}

interface IWeb3AuthState {
  env: any;
}
interface IWeb3AuthProps {
  children?: ReactNode;
  env: any;
}

interface tokenProps {
  access_token: any;
  expires_in: number;
  refresh_token: any;
  scope: any;
  token_type: any;
}

export const OAuthProvider = ({ children, env }: IWeb3AuthProps) => {
  const [user, setUser] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [authorizeUri, setauthorizeUri] = useState<string>("null");

  const setAuth = useSetRecoilState(authAtom);
  const setUsers = useSetRecoilState(usersAtom);

  useEffect(() => {
    async function init() {
      try {
        setIsLoading(true);

        const authorizeParams = {
          client_id: env.client_id,
          redirect_uri: env.redirect_uri,
          response_type: "code",
          state: "example_verify",
          scope: "basic photo profiles email wallet",
        };
        const params = serialize(authorizeParams);
        const authorizeUri: string = `${env.oAuthUrl}/oauth/authorize/?${params}`;

        setauthorizeUri(authorizeUri);
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    }

    init();
  }, []);

  const serialize = (obj: any) => {
    var str = [];
    for (var p in obj)
      if (obj.hasOwnProperty(p)) {
        str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
      }
    return str.join("&");
  };

  const login = async () => {
    try {
      setIsLoading(true);
      // window.location.href = authorizeUri;
      return await authorizeUri;
      // window.location.replace(authorizeUri)
    } catch (error) {
      console.log("error", error);
    } finally {
      // setIsLoading(false)
    }
  };

  const verifyAuthCode = async (code: any) => {
    try {
      setIsLoading(true);
      const { apiUrl, client_id, redirect_uri } = env;
      const _created = Date.now();
      const data = {
        code,
        client_id,
        redirect_uri,
        grant_type: "authorization_code",
      };

      const resp = await axios.post("oauth/authenticate", data);
      if (resp.data) {
        //set token to storage
        const data = resp.data;

        if (data.access_token) {
          localStorage.setItem("tokens", JSON.stringify(data));
          const expired = (data.expires_in / 60 - 20) * 60;
          const date_now: any = moment().unix() + expired;
          localStorage.setItem("expired_in", date_now);
          let logined = false;
          const interval = setInterval(fetchInitUser, 1000);
          async function fetchInitUser() {
            const result = await getUserInfoOM(data);
            if (result && result.wallet?.address) {
              logined = true;
              
              setUsers(result);
              clearInterval(interval);

              setIsLoading(false);
              
              const data: any = await localStorage.getItem("current");
      
              if (logined) {
                const url = data ? JSON.parse(data).url : '/';
                // localStorage.removeItem("current");
                return window.location.href = url;
                // var base_url = window.location.origin;
                // const dd = url.replace(base_url, "")
                // const base = dd.split("?")
                // navigate({
                //   pathname: base[0],
                //   search: `?${base[1]}`,
                // });
                // window.location = url.replace(base_url, "");
              }
            }
          }

          
          // const res = await getUserInfo(data);

          // init call event
          // try {
          //   const addr = await localStorage.getItem("contactAddress");
          //   if (res) await callFirstLogin(data, addr);
          // } catch (error) {
          //   console.log(error);
          // }
        }
      
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      logout();
      return false;
    }
  };

  const getUserInfoOM = async (data: tokenProps) => {
    try {
      let tokens: any = data;
      const result = await axios.get("/oauth/me", {
        headers: {
          "Content-Type": "application/json",
          Authorization: `${tokens.token_type} ${tokens.access_token}`,
        },
      });

      const res: any = result.data;
      if (res.wallet?.address) {
        return result.data;
      }
    } catch (error) {
      return null;
    }
    return null;
  };

  const logout = async () => {
    const url = `${env.oAuthUrl}/index.php?oauth=logout&client_id=${env.client_id}&redirect_to=${window.location.origin}?redirectTo=logout`;
    localStorage.removeItem("tokens");
    localStorage.removeItem("expired_in");
    // setAuth(null);
    window.location.replace(url);
  };

  const getUserInfo = async (data: tokenProps) => {
    try {
      setIsLoading(true);

      let tokens: any = data;

      const date_now: any = moment().unix();
      const expired_in: any = localStorage.getItem("expired_in");
      const date_expired: any = parseInt(expired_in);
      const token_expired = date_now > date_expired;

      if (token_expired) {
        const input = {
          client_id: env.client_id,
          refresh_token: tokens.refresh_token,
          grant_type: "refresh_token",
        };

        try {
          const res = await axios.post("/oauth/authenticate/", input, {
            headers: {
              "Content-Type": "application/json",
              Authorization: `${tokens.token_type} ${tokens.access_token}`,
            },
          });

          // set new token to session
          localStorage.setItem("tokens", JSON.stringify(res.data));

          const expired = (res.data.expires_in / 60 - 20) * 60;
          const date_now: any = moment().unix() + expired;

          localStorage.setItem("expired_in", date_now);
          tokens = res.data; //refresh new token accress
        } catch (error: any) {
          const res: any = error.response.data;
          if (res.error === "invalid_grant") {
            logout(); //error response
          }
        }
      }

      //fetch user data
      const result = await axios.get("/oauth/me", {
        headers: {
          "Content-Type": "application/json",
          Authorization: `${tokens.token_type} ${tokens.access_token}`,
        },
      });

      //   const token_object:any = localStorage.getItem('tokens');
      // console.log(token_object);

      setUsers(result.data);
    } catch (error) {
      console.log("error", error);
      setIsLoading(false);
      logout();
    } finally {
      setIsLoading(false);
      return true;
    }
  };

  const getBalance = async (account: string) => {
    const token_object: any = localStorage.getItem("tokens");
    const tokens = JSON.parse(token_object);

    // fetch balance data
    const data = {
      jsonrpc: "2.0",
      method: "wallet_getBalance",
      params: [account],
      id: 1,
    };

    const result = await axios.post("/api/wallet/rpc/", data, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `${tokens.token_type} ${tokens.access_token}`,
      },
    });
    if (result.data.result) {
      return result.data.result;
    }

    return false;
  };

  const intiWallet = async () => {
    // fetch balance data
    const data = {
      jsonrpc: "2.0",
      method: "eth_accounts",
      params: [],
      id: 1,
    };

    const result = await axios.post("/api/wallet/rpc/", data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (result.data.result) {
      return result.data.result;
    }

    return false;
  };

  const sendTransaction = async (item: any) => {
    const token_object: any = localStorage.getItem("tokens");
    const tokens = JSON.parse(token_object);

    // fetch balance data
    const data = {
      jsonrpc: "2.0",
      method: "eth_sendTransaction",
      params: item,
      id: 1,
    };

    const result = await axios.post("/api/wallet/rpc/", data, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `${tokens.token_type} ${tokens.access_token}`,
      },
    });

    if (result.data.result) {
      return result.data.result;
    }

    return false;
  };

  const parseJwt = async (token: any) => {
    var base64Url = token.split(".")[1];
    var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    var jsonPayload = decodeURIComponent(
      atob(base64)
        .split("")
        .map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );

    return JSON.parse(jsonPayload);
  };

  const callGiftNft = async (addr: any) => {
    if (addr) {
      const token_object: any = localStorage.getItem("tokens");
      const tokens = JSON.parse(token_object);

      const result = await axios.post(
        "/webhook/mystery-box",
        {
          addr: addr,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `${tokens.token_type} ${tokens.access_token}`,
          },
        }
      );

      if (result.data.result) {
        await localStorage.removeItem("contactAddress");
        return result.data.result;
      }
    }

    return false;
  };

  const getWallet = async (tokens: any) => {
    const result = await axios.post("/api/wallet/rpc/", null, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `${tokens.token_type} ${tokens.access_token}`,
      },
    });

    if (result.data.result) {
      console.log(result.data.result);

      return true;
    }
    console.log(result);

    return false;
  };

  const pushEvent = async () => {
    const token_object: any = localStorage.getItem("tokens");
    const tokens = JSON.parse(token_object);
    // fetch balance data
    const data = {
      type: "GAS_REQUIRE",
      data: {},
    };

    const result = await axios.post("/api/account/event/push", data, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `${tokens.token_type} ${tokens.access_token}`,
      },
    });

    if (result.data.result) {
      return true;
    }
    return false;
  };

  const refreshToken = async () => {
    const token_object: any = localStorage.getItem("tokens");
    const expired_in: any = localStorage.getItem("expired_in");
    const tokens = JSON.parse(token_object);

    const date_now: any = moment().unix();
    const date_expired: any = parseInt(expired_in);
    const token_expired = date_now > date_expired;
    if (token_expired) {
      const input = {
        client_id: env.client_id,
        refresh_token: tokens.refresh_token,
        grant_type: "refresh_token",
      };

      try {
        const res = await axios.post("/oauth/authenticate/", input, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `${tokens.token_type} ${tokens.access_token}`,
          },
        });

        // set new token to session
        localStorage.setItem("tokens", JSON.stringify(res.data));

        const expired = (res.data.expires_in / 60 - 20) * 60;

        const date_now: any = moment().unix() + expired;

        localStorage.setItem("expired_in", date_now);
      } catch (error: any) {
        const res: any = error.response.data;
        if (res.error === "invalid_grant") {
          logout(); //error response
        }
      }
    }
  };

  const contextProvider = {
    user,
    isLoading,
    login,
    logout,
    getUserInfo,
    getBalance,
    sendTransaction,
    verifyAuthCode,
    pushEvent,
    refreshToken,
    callGiftNft,
  };
  return (
    <OAuthContext.Provider value={contextProvider}>
      {children}
    </OAuthContext.Provider>
  );
};
