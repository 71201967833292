import { useOAuth } from "@services/Oauth";
import React, { Component, useEffect, useState } from "react";

import Loader from "@components/Loader";

const Login = (props: any) => {

  const { login } = useOAuth();
  useEffect(() => {
    
    const loadUrl = async () => {
      const url = await login();
      if (url && !props.code) {
        window.location.replace(url);
      }
    };
    loadUrl();
  }, []);

  return <Loader />;
};

export default Login;
