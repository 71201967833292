import { FunctionComponent, useCallback, useState,useEffect } from "react";
// import "antd/dist/antd.css";
// import { Input } from "antd";
import styles from "./css/Rewards.module.css";
import { TextField, InputAdornment, Icon, Button } from "@mui/material";
import LoadingButton from '@mui/lab/LoadingButton';

import { inputLabelClasses } from "@mui/material/InputLabel";
import { useRecoilValue } from "recoil";
import { authAtom,usersAtom } from "../../../src/state";
import axios from "axios";
import { useOAuth } from "@services/Oauth";


import { useNavigate,useLocation } from "react-router-dom"; 

import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import { makeStyles } from '@mui/styles';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';

import Loader from "@components/Loader";

const useStyles = makeStyles({
  breadcrumbs: {
    "& .MuiBreadcrumbs-separator": {
      color: "#fff",
      fontSize:'10px'
    },
  
  },

});


export const Rewards: FunctionComponent = () => {
  
  const { logout,getBalance,pushEvent,refreshToken } = useOAuth();
  const auth:any = useRecoilValue(authAtom);
  const user:any = useRecoilValue(usersAtom);

  const navigate = useNavigate();

  const [code, setCode] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const classes = useStyles();


  //check balance
  useEffect(() => {
    const fetchBalance = async ()=>{
      const balance = await getBalance(user.wallet.address)
      const total:any = parseFloat(balance[0].balance).toFixed(2);
      if (total < 0.5){
        await pushEvent()
      }
      
    }

    const initToken = async()=>{
      await refreshToken();
    }

    if (user) fetchBalance();

    //check refresh token
    initToken();
    return () => {}
  }, [user])


  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setError(false)
    let result = event.target.value.replace(/[^a-zA-Z0-9]+/g, '');
    // let result = event.target.value;
    result =result.toUpperCase()
    setCode(result);
  };

  const RedeemCode = async ()=>{

    setError(false)
    if (!code) {
      setError(true)
      return false;
    }
    setLoading(false);
    if (auth){
      setLoading(true);
      const data = {
        Code:code
      }
  
      const result = await axios.post('/webhook/redeem',data, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `${auth.token_type} ${auth.access_token}`
        }
      }).then(function (response) {
        
       
        const data = response.data.Status
        setTimeout(function() {
          setLoading(false);
          return navigate('/redeem-status', {
            state: {
              data,
            },
            replace: true 
          });
        }, 5000);
      })
      .catch(function (error) {

        if (error.response) {
          if (error.response.status === 403){
            logout()
          }
        }

        const data = error.response.data
        setTimeout(function() {
          setLoading(false);
          return navigate('/redeem-status-fail', {
            state: {
              data,
            },
            replace: true 
          })
        }, 5000);
      });


      
    }
    
  }

  if (loading) return <Loader/>
  
  return (
    <div className={styles.rewardsDiv}>
      <div className={styles.pAGETITLEDiv}>
        <div className={styles.pAGETITLEDiv1}>
          <span onClick={() => navigate("/")} className={styles.BackHistory}>
            <ChevronLeftIcon/>
            <b className={styles.rewardsRedemptionB}>Rewards Redemption</b>
          </span> 
        </div>
        <div role="presentation">
            <Breadcrumbs aria-label="breadcrumb" className={classes.breadcrumbs} separator={<KeyboardArrowRightIcon fontSize={'small'}/>}>
              <Link underline="hover" color="white" onClick={() => navigate("/")} fontSize={12}>
                Home
              </Link>
              <Typography color="white" fontSize={12}>Rewards Redemption</Typography>
            </Breadcrumbs>
          </div>
      </div>
      <div className={styles.frameDiv1}>
        <div className={styles.pAGECONTENTDiv}>
          <div className={styles.colDiv}>
           
            <div className={styles.frameDiv2}>
              <b className={styles.enterGiftCardPromoCode}>
              Enter your code:
              </b>
              <TextField
                autoComplete="off"
                error={error}
                id="filled-error-helper-text"
                fullWidth
                hiddenLabel
                className={styles.transferInput}
                color="error"
                type="text"
                size="medium"
                margin="none"
                variant="filled"
                helperText={error ? "Please enter valid code.":""}
                value={code}
                onChange={handleChange}
                InputLabelProps={{
                  sx: {
                    // set the color of the label when not shrinked
                    color: "black",
                    [`&.${inputLabelClasses.shrink}`]: {
                      // set the color of the label when shrinked (usually when the TextField is focused)
                      color: "black"
                    }
                  }
                }}
              />
             
            </div>

            <LoadingButton
              className={styles.confirmRedeemDiv}
              loading={loading}
              sx={{ 
                width: '100%',
                textTransform: 'unset' 
              }}
              variant="contained"
              color="error"
              onClick={RedeemCode}
            >
              REDEEM
            </LoadingButton>
            {/* <div className={styles.confirmRedeemDiv}>
              <b className={styles.redeemB}>Redeem</b>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};
