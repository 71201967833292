import { FunctionComponent, useRef, useState, useEffect } from "react";
import styles from "../css/MyCollectionsDetails.module.css";

interface CardProps {
  type?: any;
  level?: any;
  benefit?: any;
}

export const Creator: FunctionComponent<CardProps> = ({
  type,
  level,
  benefit,
}) => {
  //render
  function renderBagde() {
    if (level.length > 0){
      
      if (level[0].value === "Normal" || level[0].value === "Common") {
        return (
          <div className={styles.cardSeller01DivStart}>
            <div className={styles.BadgeLabelNormal}>
              {benefit.length > 0 ? (
                <b>
                  {level[0].value}-{benefit[0].value}
                </b>
              ) : (
                <b>{level[0].value}</b>
              )}
            </div>
          </div>
        );
      } else if (
        level[0].value === "rare" ||
        level[0].value === "Rare" ||
        level[0].value === "Master"
      ) {
        return (
          <div className={styles.cardSeller01DivStart}>
            <div className={styles.BadgeLabelRare}>
              {benefit.length > 0 ? (
                <b>
                  {level[0].value}-{benefit[0].value}
                </b>
              ) : (
                <b>{level[0].value}</b>
              )}
            </div>
          </div>
        );
      } else {
        return (
          <div className={styles.cardSeller01DivStart}>
            <div className={styles.BadgeLabelSpeical}>
              {benefit.length > 0 ? (
                <b>
                  {level[0].value}-{benefit[0].value}
                </b>
              ) : (
                <b>{level[0].value}</b>
              )}
            </div>
          </div>
        );
      }
    }else{
      return <div/>;
    }
    
  
  }

  if (type === "CDS") {
    return (
      <div className={styles.creatorDiv}>
        {renderBagde()}
        <div className={styles.cardSeller01Div}>
          <div className={styles.imageDiv}>
            <img
              className={styles.frameIcon}
              alt=""
              src="../logo-central.svg"
            />
          </div>
          <span className={styles.supersportsB}>Central Department Store</span>
        </div>
      </div>
    );
  } else if (type === "B2S") {
    return (
      <div className={styles.creatorDiv}>
        {renderBagde()}
        <div className={styles.cardSeller01Div}>
          <div className={styles.imageDiv}>
            <img className={styles.frameIcon} alt="" src="../Logo-b2s.svg" />
          </div>
          <span className={styles.supersportsB}>B2S</span>
        </div>
      </div>
    );
  // } else if (type === "BMT") {
  //   return (
  //     <div className={styles.creatorDiv}>
  //       <div className={styles.cardSeller01DivStart}>&nbsp;</div>
  //       <div className={styles.cardSeller01Div}>
  //         <div className={styles.imageDiv}>
  //           <img className={styles.frameIcon} alt="" src="../Logo-BMT.svg" />
  //         </div>
  //         <span className={styles.supersportsB}>Block Mountain</span>
  //       </div>
  //     </div>
  //   );
  // } 
  }else {
    return <div />;
  }
};
