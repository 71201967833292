import { useOAuth } from "@services/Oauth";
import React, { Component, useEffect, useState } from "react";

import Loader from "@components/Loader";
import { useNavigate } from "react-router-dom";

const Callback = (props: any) => {
  const { verifyAuthCode } = useOAuth();
  const navigate = useNavigate();
  
  useEffect(() => {
   
    const verify = async (code: string | (string | null)[]) => {
        const res = await verifyAuthCode(code);
  
        // if (res) return window.location.href = '/';
        const data: any = await localStorage.getItem("current");
      
        if (data && res) {
          const url = JSON.parse(data).url;

          // localStorage.removeItem("current");
       
          return window.location.href = url;
          // var base_url = window.location.origin;
          // const dd = url.replace(base_url, "")
          // const base = dd.split("?")
          // navigate({
          //   pathname: base[0],
          //   search: `?${base[1]}`,
          // });
          // window.location = url.replace(base_url, "");
        }

  
     
      };
  
      if ( props.code) {
        verify(props.code);
      }
    

  }, []);

  return <Loader />;
};

export default Callback;
