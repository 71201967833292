import React, { FunctionComponent, useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router";
import Web3 from "web3";
import { ABI, ADDRESS } from "@contracts/NFT";
import axios from "axios";
import Image from "@components/Image";
import styles from "./css/MyCollectionsDetails.module.css";
import Loader from "@components/Loader";
import { Creator } from "./elements/Creator";
// import MetaTags from "react-meta-tags";
import { Helmet } from "react-helmet";
import { ReactTitle } from "react-meta-tags";
export const Preview: FunctionComponent = () => {
  const web3: any = new Web3("https://rpc.omplatform.com/");
  const [loading, setLoading] = useState(false);
  const [detail, setDetail]: any = useState(null);
  const [item_type, setItemType]: any = useState(null);
  const [item_level, setItemLevel]: any = useState(null);
  const [item_benefit, setItemBenefit]: any = useState(null);

  const navigate = useNavigate();

  const { addr, id } = useParams();

  useEffect(() => {
    const initNFT = async () => {
      setLoading(true);
      const token_id: any = id;
      const contract = await new web3.eth.Contract(ABI, addr);

      const addrress = await contract.methods.ownerOf(token_id).call();

      getNFTMetadata(contract, token_id);
    };

    initNFT();

    return () => {};
  }, []);

  async function getNFTMetadata(contract: any, tokenID: string) {
    const result = await contract.methods.tokenURI(tokenID).call();

    const ipfsURL = addIPFSProxy(result);
    if (ipfsURL) {
      try {
        const request: any = new Request(ipfsURL);
        const response = await fetch(request);
        const metadata = await response.json();

        const images: string = addIPFSProxy(metadata.image);
        const data: any = {
          ...metadata,
          images,
          tokenID,
          contractAddress: contract._address,
        };
       
        setDetail(data);

        // //set attr
        const att = data.attributes;
        var item_type = att.filter(function (item: any) {
          return item.trait_type === "Type";
        });

        setItemType(item_type);

        const item_level = att.filter(function (item: any) {
          return item.trait_type === "Level";
        });
        setItemLevel(item_level);
        const item_benefit = att.filter(function (item: any) {
          return item.trait_type === "Benefit";
        });
        setItemBenefit(item_benefit);

        // setDetail(old => [...old, data])
        setLoading(false);
      } catch (error) {
        console.log(error);
      }
    }
  }
  function addIPFSProxy(ipfsHash: string) {
    // if (!ipfsHash.startsWith('https://'))  return ipfsHash.replace('https://c-collectible-static.s3.ap-southeast-1.amazonaws.com', '')
    if (!ipfsHash.startsWith("ipfs://")) {
      const hash = ipfsHash.replace(
        "https://c-collectible-static.s3.ap-southeast-1.amazonaws.com",
        ""
      );
      return hash;
    }
    // const URL = "https://cloudflare-ipfs.com/ipfs/"

    // const ipfsURL = URL + hash
    // return ipfsHash
    return "";
  }

  function renderImage() {
    return (
      <Image
        className={`${styles.itemImageDiv}`}
        src={detail.images}
        alt={detail.name}
      />
    );
  }

  if (!detail || loading) return <Loader />;

  const goToHome = () => {
    navigate("/");
  };

  const shareLink =
    window.location.origin +
    "/preview/" +
    detail.contractAddress +
    "/" +
    detail.tokenID;
  return (
    <>
      {/* <ReactTitle title={`My NFT ${detail.name}`}/> */}

      {/* <MetaTags>
       <title>My NFT {detail.name}</title>
        <meta name="description" content={detail.description} />
        <meta name="keywords" content="nft, womenday central, c-collectible" />
        <meta name="author" content="Central Retail" />
        <meta property="og:title" content={detail.name} />
        <meta property="og:description" content={detail.description} />
        <meta property="og:image" content={detail.image} />
        <meta property="og:url" content={shareLink} />
        <meta name="twitter:title" content={detail.name} />
        <meta name="twitter:description" content={detail.description} />
        <meta name="twitter:image" content={detail.image} />
        <meta name="twitter:card" content="summary_large_image" />
          </MetaTags> */}
      <Helmet>
        <title>{`My NFT ${detail.name}`}</title>
        <meta name="description" content="I/PROMPTING] INCLUSIVITY FOR ALL WOMEN ให้ผู้หญิง[/พร้อมเป็นผู้ขับเคลื่อนอย่างแท้จริง" />
        <meta property="og:title" content={`My NFT ${detail.name}`} />
        <meta property="og:description" content="I/PROMPTING] INCLUSIVITY FOR ALL WOMEN ให้ผู้หญิง[/พร้อมเป็นผู้ขับเคลื่อนอย่างแท้จริง" />
        <meta
          property="og:image"
          content="https://wallet.central.tech/favicon/android-chrome-192x192.png"
        />
        <meta property="og:url" content={window.location.origin} />
        <meta name="twitter:title" content={`My NFT ${detail.name}`} />
        <meta name="twitter:description" content="I/PROMPTING] INCLUSIVITY FOR ALL WOMEN ให้ผู้หญิง[/พร้อมเป็นผู้ขับเคลื่อนอย่างแท้จริง" />
        <meta
          name="twitter:image"
          content="https://wallet.central.tech/favicon/android-chrome-192x192.png"
        />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>
      <div className={styles.myCollectionsDetailsDiv}>
        <div className={styles.pAGETITLEDiv}>
          <div className={styles.pAGETITLEDiv1}>
            <span className={styles.BackHistory} onClick={() => goToHome()}>
              <b className={styles.transferItemB}>
                {detail.name} {`#${detail.tokenID}`}
              </b>
            </span>
          </div>
        </div>
        <div className={styles.frameDiv3}>
          <div className={styles.pAGECONTENTDiv}>
            <div className={styles.colDiv}>
              {renderImage()}
              {/* <button onClick={toggle}>{playing ? "Pause" : "Play"}</button> */}
            </div>

            <div className={styles.colDiv1}>
              <div className={styles.cardSeller01Div}>
                <div className={styles.imageDiv}>
                  <img
                    className={styles.frameIcon}
                    alt="logo"
                    src="/logo-central.svg"
                  />
                </div>

                <div className={styles.divTitle}>
                  <div className={styles.textBy}>By {detail.creator}</div>
                  <div className={styles.textTitle}>
                    {detail.name} # {detail.tokenID}
                  </div>
                </div>
              </div>
              {/* <Creator
              type={detail.creator}
              level={item_level}
              benefit={item_benefit}
            /> */}
              {detail.description !== "" ? (
                <div>
                  <b className={styles.victoriaOilpaint07}>Desciption</b>
                  <div className={styles.notTheSharpestToolInTheS}>
                    {detail.description}
                  </div>
                </div>
              ) : (
                <div> &nbsp;</div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
