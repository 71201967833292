import { FunctionComponent } from "react";
import styles from "./css/RewardsStatus.module.css";
import {  Button } from "@mui/material";
import { useLocation,useNavigate } from "react-router-dom";

export const GiftStatus: FunctionComponent = () => {

  const location = useLocation();
  const data:any = location.state;
  const navigate = useNavigate();

  
  if (!data) navigate('/')
  
  return (
    <div className={styles.rewardsStatusDiv}>
      <div className={styles.pAGETITLEDiv}>
        <div className={styles.colDiv}>
            <img
              alt=""
              src="success.svg"
            />
             <b className={styles.redemptionSuccessfulB}>SUCCESSFUL!</b>
        
        </div>
      </div>
      <div className={styles.frameDiv1}>
      
        <div className={styles.pAGECONTENTDiv}>
          <div className={styles.colDiv}>
            {/* <div className={styles.showThisQRCodeToRedeemIn}>
              Congratulations
            </div> */}
            <div className={styles.showThisQRCodeToRedeemIn}>
              RECEIVE COMPLETE
            </div>
          
            {/* <div className={styles.confirmDoneDiv}>
              <b className={styles.redemptionSuccessfulB}>Done</b>
            </div> */}
          </div>
          
        </div>

        <Button
              className={styles.confirmDoneDiv}
              sx={{ width: '100%', textTransform: 'unset' }}
              variant="contained"
              color="error"
              href="/"
            >
            OK
            </Button>
      </div>
    </div>
  );
};
