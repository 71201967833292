import React, { FunctionComponent, useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router";
import Web3 from "web3";
import { ABI, ADDRESS } from "@contracts/NFT";
import axios from "axios";
import Image from "@components/Image";
import styles from "./css/MyCollectionsDetails.module.css";
import Loader from "@components/Loader";
import { Helmet } from "react-helmet-async";
import { Button } from "@mui/material";
import { useOAuth } from "@services/Oauth";
import { useRecoilValue } from "recoil";
import { authAtom } from "../../state";
import { ReactI18NextChild } from "react-i18next";

const womens = [
  "0xD10B8cB5312B8Ff3E46964a1BCcFb8358F4d55d5",
  "0x46583209C6baf35A0E5a82A46Ba5f51Fcf8C8c8A",
  "0x384589D4DAE51CC8f64fBFF3F1271e5402d107df",
  "0x416e4Ac0e1E1892Bf398390553eE104AfC4E3a2D",
];

export const GiftAll: FunctionComponent = () => {
  const web3: any = new Web3("https://rpc.omplatform.com/");
  const [loading, setLoading] = useState(false);
  const [detail, setDetail]: any = useState([]);
  const [item_type, setItemType]: any = useState(null);
  const [item_level, setItemLevel]: any = useState(null);
  const [item_benefit, setItemBenefit]: any = useState(null);


  const [selectedNFT, setSelectedNFT]: any = useState(null);

  

  const auth: any = useRecoilValue(authAtom);
  const navigate = useNavigate();
  const { logout } = useOAuth();

  useEffect(() => {
    const initNFT = async () => {
      setLoading(true);
      const token_id: any = 1;
      womens.forEach(async (element) => {
        const contract = await new web3.eth.Contract(ABI, element);
        getNFTMetadata(contract, token_id);
      });
    };

    initNFT();

    return () => {};
  }, []);

  async function getNFTMetadata(contract: any, tokenID: string) {
    const result = await contract.methods.tokenURI(tokenID).call();

    const ipfsURL = addIPFSProxy(result);
    if (ipfsURL) {
      try {
        const request: any = new Request(ipfsURL);
        const response = await fetch(request);
        const metadata = await response.json();

        const images: string = addIPFSProxy(metadata.image);
        const data: any = {
          ...metadata,
          images,
          tokenID,
          contractAddress: contract._address,
        };
      
        // setDetail([...detail,data]);
        setDetail((prevState: any) => [...prevState, data]);
        // setDetail(old => [...old, data])
        setLoading(false);
      } catch (error) {
        console.log(error);
      }
    }
  }
  function addIPFSProxy(ipfsHash: string) {
    // if (!ipfsHash.startsWith('https://'))  return ipfsHash.replace('https://c-collectible-static.s3.ap-southeast-1.amazonaws.com', '')
    if (!ipfsHash.startsWith("ipfs://")) {
      const hash = ipfsHash.replace(
        "https://c-collectible-static.s3.ap-southeast-1.amazonaws.com",
        ""
      );
      return hash;
    }
    // const URL = "https://cloudflare-ipfs.com/ipfs/"

    // const ipfsURL = URL + hash
    // return ipfsHash
    return "";
  }

  const renderImage = () => {
    detail.forEach((item: any) => {
      return (
        <Image
          className={`${styles.itemImageDiv}`}
          src={item.images}
          alt={item.name}
        />
      );
    });
  };

  const goToHome = () => {
    navigate("/");
  };

  const selectNFT = (index:any) =>{
    setSelectedNFT(index)
  }

  const recieveNFT = async () => {

    if (selectedNFT === null){
      return false;      
    }

   return navigate(`/gift/${detail[selectedNFT].contractAddress}`);
  };

  if (!detail || loading) return <Loader />;
 
  const NFTList = detail.map((item: any,index :any) => (
    <div key={index} className={`${styles.gridPadding} ${index === selectedNFT ?styles.gridPaddingSelectd :null}`}  onClick={() => selectNFT(index)}>
      <Image
        className={`${styles.gridImageDiv}`}
        src={item.images}
        alt={item.name}
      />
      <div className={styles.gridSeller01Div}>{item.name}</div>
    </div>
  ));

  return (
    <>
      {/* <Helmet prioritizeSeoTags>
        <title>Gift{detail.name}</title>
        <meta name="description" content={detail.description} />
        <meta name="keywords" content="nft, womenday central, c-collectible" />
        <meta name="author" content="Central Retail" />
        <meta property="og:title" content={detail.name} />
        <meta property="og:description" content={detail.description} />
        <meta property="og:image" content={detail.image} />
        <meta property="og:url" content={shareLink} />
        <meta name="twitter:title" content={detail.name} />
        <meta name="twitter:description" content={detail.description} />
        <meta name="twitter:image" content={detail.image} />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet> */}
      <div className={styles.myCollectionsDetailsDiv}>
        <div className={styles.frameDiv3}>
          <h3>SHEPOSSIBLE</h3>
          <small className={styles.textCenter}>I/PROMPTING] INCLUSIVITY FOR ALL WOMEN <br/>
ให้ผู้หญิง[/พร้อม]เป็นผู้ขับเคลื่อนอย่างแท้จริง</small>
          <div className={styles.pAGECONTENTDiv}>
            <div className={styles.gridDiv}>{NFTList}</div>

            <div className={styles.colDiv1}>
              <div className={styles.frameDiv5}>
                <Button
                
                  disabled={selectedNFT !== null ? false:true}
                  className={styles.btnTransfer}
                  sx={{
                    width: 300,
                    textTransform: "unset",
                    "&.Mui-disabled": {
                      background: "#c0c0c0",
                      color: "#000"
                    }
                  }}
                  variant="contained"
                  color="error"
                  onClick={() => recieveNFT()}
                >
                  CHOOSE
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
